import React, { useCallback, useMemo, useRef } from "react";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useAtom } from 'jotai'
import { useTracking } from "../../common/contexts/tracking";
import { getFirebaseInstance } from "../../common/firebase";
import { CHECK_USER } from "../../graphqlQueries";
import { checkUserAuthState } from "../../atoms";
import { setCookie } from "../../utils";
import InputText from "../input-text";
import Button from "../button";
import Checkbox from "../checkbox";
import { commonAuthDeleteCookies, commonAuthTracking } from "../../common/auth/utils";


const RegisterUser = ({ signInSuccessCallback }) => {

  //local states
  const [error, setError] = useState();
  const [userRegFormData, setUserRegFormData] = useState({})
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false)

  const tracking = useTracking();
  const isEmailValid = useRef(false)

  //global states
  const [checkUserAuth, setCheckUserAuth] = useAtom(checkUserAuthState)

  //API functions

  const [checkUser] = useMutation(CHECK_USER);

  const isSubmitButtonEnabled = useMemo(() => userRegFormData?.email && userRegFormData?.displayName && userRegFormData?.password, [userRegFormData])

  const handleRegisterNewUser = async () => {
    if (!isSubmitButtonEnabled || !isEmailValid.current) return
    if (error) setError(null)
    
    const firebaseInstance = getFirebaseInstance();
   
    try {
      setSubmitButtonLoading(true)
      let checkUserData = checkUserAuth
      if (!checkUserAuth) {
        // checking if user already exists in DB or not
        const checkUserResponse = await checkUser({
          variables: {
            logInMode: 'google',
            email: userRegFormData.email
          }
        })
        checkUserData = checkUserResponse?.data?.checkUser
        setCheckUserAuth(checkUserData)
      }

      if (!checkUserData?.status) {
        throw new Error(checkUserData?.message)
      }

      setCookie("__user_displayName", userRegFormData.displayName);
      const userCredential = await firebaseInstance.auth.createUserWithEmailAndPassword(
        userRegFormData.email,
        userRegFormData.password
      );

      const user = userCredential.user;
      await user.updateProfile({ displayName: userRegFormData.displayName });
      user.displayName = userRegFormData.displayName

      commonAuthTracking({ tracking, user , type: "Register"})
    
      signInSuccessCallback && signInSuccessCallback(user);
    } catch (err) {
      console.log('Error While registering User', err);
      setError(err.message);
      setCheckUserAuth(null)
      commonAuthDeleteCookies()
    } finally {
      setSubmitButtonLoading(false)
    }
  };

  const onChangeField = useCallback(fieldData => {
    setUserRegFormData(oldState => ({...oldState, ...fieldData}))
  },[])

  const onEmailValidationChange = useCallback(validationStatus => {
    isEmailValid.current = validationStatus
  }, [])

  return (
    <div className="signup-container">
      <div className="signup-top-text">Create your Bimmatch account</div>
      
      <div className="signup-form">
        <InputText onChange={onChangeField} label={'Email'} fieldName={'email'} placeholder='Your email' validations={{required: true, email: true}} required={true} showValidationMessage={true} onValidationChange={onEmailValidationChange}/>
        <InputText onChange={onChangeField} label={'Name'} fieldName={'displayName'} placeholder='Your full name' validations={{required: true}} required={true} showValidationMessage={true}/>
        <InputText onChange={onChangeField} label={'Organization'} fieldName={'organization'} placeholder='Organization' showValidationMessage={true}/>
        <InputText onChange={onChangeField} label={'Password'} fieldName={'password'} placeholder='Define a password' validations={{required: true}} type='password' required={true} showValidationMessage={true}/>
        <Checkbox onChange={onChangeField} fieldName={'bimmatchMarketing'} label={'I agree to receive marketing communication forms Bimmatter (Bimmatch platform)'} showValidationMessage={true}/>
        <Checkbox onChange={onChangeField} fieldName={'productsMarketing'} label={'I agree to receive marketing communication forms distributors of products featured on the platform'} showValidationMessage={true}/>
        <Button type='secondary' disable={!isSubmitButtonEnabled || submitButtonLoading} loading={submitButtonLoading} onClick={handleRegisterNewUser}>SIGN UP</Button>
        {error && (
            <p style={{ display: "flex" }} className="registerError">
              {error}
            </p>
        )}
      </div>
      
      <div className="login-notice">
        {"By submitting your details you agree to our "}
        <a
          href="https://www.bimmatch.com/terms-of-use"
          alt="Terms and Conditions"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use
        </a>
        {" and "}
        <a
          href="https://www.bimmatch.com/privacy-policy"
          alt="Privacy Policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  );
};

export default RegisterUser;
