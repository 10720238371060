import React, { useEffect, useRef } from 'react'
import ImageCard from '../image-card';

const ImageCards = ({ imagesData, className, scrollTo }) => {
    const imagesContainer = useRef(null);
    useEffect(() => {
        if (imagesContainer.current && scrollTo) {
            setTimeout(() => {
                const image = imagesContainer.current.querySelector(`#spec-image-id-${scrollTo}`);
                if (image) {
                    image.scrollIntoView({ behavior: 'smooth' });
                }
            });
        }
    }, [scrollTo])

    return (
        <div className={`${className} image-cards`} ref={imagesContainer}>
            {imagesData.map(figureData => (
                <ImageCard
                    {...figureData}
                    id={"spec-image-id-" + figureData.id}
                    key={figureData.id}
                    width="100%"
                    height="auto"
                    zoom
                />
            ))}
        </div>
    )
}

export default ImageCards