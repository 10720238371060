import React, { useCallback } from 'react'

const CustomAnchor = ({ children, onClick, href, target }) => {
    const handleClick = useCallback(() => {
        if (onClick) {
            onClick()
        }
    }, [onClick])

    return (
        <>
            {
                !!href ? <a className='bimmatch-anchor-tag' href={href} target={target}>{children}</a> : <span className='bimmatch-anchor-tag' onClick={handleClick}>{children}</span>
            }
        </>
    )
}

export default CustomAnchor