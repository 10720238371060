import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import { organizationState, userFeaturesPermissionsState } from "./atoms";
import { useRecoilValue } from "recoil";
import { useAtomValue } from "jotai";
import { CONSTANTS } from "./constants";
import AuthContext from "./common/contexts/auth";
import ConnectAcc from "./components/login/connect-acc";
import lazyWithRetry from "./lazyRetry";

//-Projects------
const Invite = lazyWithRetry(() => import("./pages/invite"));
const Analytics = lazyWithRetry(() => import("./pages/analytics"));
// const Project = lazyWithRetry(() => import("./pages/project-detail"));
const ProjectDetail = lazyWithRetry(() => import("./pages/project-detail"));
const NewProject = lazyWithRetry(() =>
  import("./pages/project-detail/new-project")
);
const Projects = lazyWithRetry(() => import("./pages/projects"));

const OrganizationLibrary = lazyWithRetry(() =>
  import("./pages/organization-library")
);
const DemoReport = lazyWithRetry(() => import("./pages/demo-report"));

//-Product------
const CreateProduct = lazyWithRetry(() =>
  import("./pages/products/create-product")
);
const UpdateProduct = lazyWithRetry(() =>
  import("./pages/products/update-product")
);
const Products = lazyWithRetry(() => import("./pages/products"));
const Product = lazyWithRetry(() => import("./pages/product-detail"));
const Team = lazyWithRetry(() => import("./pages/team"));
const Specs = lazyWithRetry(() => import("./pages/specs"));

//-Settings------
const PersonalSettings = lazyWithRetry(() => import("./pages/settings"));
const OrganizationSettings = lazyWithRetry(() =>
  import("./pages/settings/organization")
);
const Actions = lazyWithRetry(() => import("./pages/settings/actions"));

//Auth
const AutodeskAuth = lazyWithRetry(() =>
  import("./components/login/autodesk-auth")
);

const Routes = ({ props }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const { isPlanner, isVendor } = useRecoilValue(organizationState);
  const userFeaturesPermissions = useAtomValue(userFeaturesPermissionsState);

  return (
    <Router>
      <Switch>
        <Route
          path="/"
          exact
          render={(props) => <Redirect to={{ pathname: "/products" }} />}
        />
        <Route
          path="/settings"
          exact
          render={(props) => (
            <Redirect to={{ pathname: "/settings/personal" }} />
          )}
        />
        <PrivateRoute
          path="/analytics"
          exact
          authAccess={
            !!userFeaturesPermissions?.permissions?.[
            CONSTANTS.PERMISSIONS.ANALYTICS_VIEW
            ]
          }
          render={(props) => (
            <React.Suspense fallback={<FallbackLoader />}>
              <Analytics {...props} />
            </React.Suspense>
          )}
        />
        {isAuthenticated ? (
          <PrivateRoute
            path="/projects"
            exact
            authAccess={!!userFeaturesPermissions?.features?.[CONSTANTS.FEATURES.PROJECT_VIEW] && !!userFeaturesPermissions?.permissions?.[CONSTANTS.PERMISSIONS.PROJECT_VIEW]}
            render={(props) => (
              <React.Suspense fallback={<FallbackLoader />}>
                <Projects {...props} />
              </React.Suspense>
            )}
          />
        ) : (
          <Route
            path="/projects"
            exact
            render={(props) => (
              <React.Suspense fallback={<FallbackLoader />}>
                <Projects {...props} />
              </React.Suspense>
            )}
          />
        )}
        <PrivateRoute
          path="/project/new"
          exact
          authAccess={(isPlanner || isVendor) && !!userFeaturesPermissions?.permissions?.[CONSTANTS.PERMISSIONS.CREATE_PROJECT_BUTTON_VIEW]}
          render={(props) => (
            <React.Suspense fallback={<FallbackLoader />}>
              <NewProject {...props} />
            </React.Suspense>
          )}
        />
        {/* <PrivateRoute
          path="/project/:id"
          exact
          render={(props) => (
            <React.Suspense fallback={<FallbackLoader/>}>
              <Project {...props} />
            </React.Suspense>
          )}
        /> */}
        <PrivateRoute
          path="/project/:id"
          exact
          render={(props) => (
            <React.Suspense fallback={<FallbackLoader />}>
              <ProjectDetail {...props} />
            </React.Suspense>
          )}
        />
        <Route
          path="/products"
          exact
          render={(props) => (
            <React.Suspense fallback={<FallbackLoader />}>
              <Products {...props} />
            </React.Suspense>
          )}
        />
        <Route
          path="/products/:id"
          exact
          render={(props) => (
            <React.Suspense fallback={<FallbackLoader />}>
              <Product {...props} />
            </React.Suspense>
          )}
        />
        <Route
          path="/invite/:type/:token"
          exact
          render={(props) => (
            <React.Suspense fallback={<FallbackLoader />}>
              <Invite {...props} />
            </React.Suspense>
          )}
        />
        <PrivateRoute
          path="/settings/actions"
          exact
          render={(props) => (
            <React.Suspense fallback={<FallbackLoader />}>
              <Actions {...props} />
            </React.Suspense>
          )}
        />
        <PrivateRoute
          path="/settings/personal"
          exact
          render={(props) => (
            <React.Suspense fallback={<FallbackLoader />}>
              <PersonalSettings {...props} />
            </React.Suspense>
          )}
        />
        <PrivateRoute
          path="/settings/organization"
          exact
          authAccess={(isPlanner || isVendor) && !!userFeaturesPermissions?.permissions?.[CONSTANTS.PERMISSIONS.ORGANIZATION_ACCOUNT_VIEW]}
          render={(props) => (
            <React.Suspense fallback={<FallbackLoader />}>
              <OrganizationSettings {...props} />
            </React.Suspense>
          )}
        />
        <PrivateRoute
          path="/product/create"
          exact
          authAccess={(isPlanner || isVendor) && !!userFeaturesPermissions?.permissions?.[CONSTANTS.PERMISSIONS.PRODUCT_EDIT]}
          render={(props) => (
            <React.Suspense fallback={<FallbackLoader />}>
              <CreateProduct {...props} />
            </React.Suspense>
          )}
        />

        <PrivateRoute
          path="/product/update/:id"
          exact
          authAccess={(isPlanner || isVendor) && !!userFeaturesPermissions?.permissions?.[CONSTANTS.PERMISSIONS.PRODUCT_EDIT]}
          render={(props) => (
            <React.Suspense fallback={<FallbackLoader />}>
              <UpdateProduct {...props} />
            </React.Suspense>
          )}
        />

        <PrivateRoute
          path="/organization-items"
          exact
          authAccess={(isPlanner || isVendor) && !!userFeaturesPermissions?.permissions?.[CONSTANTS.PERMISSIONS.ORG_LIB_VIEW]}
          render={(props) => (
            <React.Suspense fallback={<FallbackLoader />}>
              <OrganizationLibrary {...props} />
            </React.Suspense>
          )}
        />

        <PrivateRoute
          path="/team"
          exact
          authAccess={!!userFeaturesPermissions?.permissions?.[CONSTANTS.PERMISSIONS.TEAM_VIEW]}
          render={(props) => (
            <React.Suspense fallback={<FallbackLoader />}>
              <Team {...props} />
            </React.Suspense>
          )}
        />

        <PrivateRoute
          path="/specs"
          exact
          render={(props) => (
            <React.Suspense fallback={<FallbackLoader />}>
              <Specs {...props} />
            </React.Suspense>
          )}
        />

        <Route
          path="/demo-report"
          exact
          render={(props) => (
            <React.Suspense fallback={<FallbackLoader />}>
              <DemoReport {...props} />
            </React.Suspense>
          )}
        />

        <Route
          path="/autodesk-auth"
          exact
          render={(props) => (
            <React.Suspense fallback={<FallbackLoader />}>
              <AutodeskAuth {...props} />
            </React.Suspense>
          )}
        />

        <Route
          path="/connect-acc"
          exact
          render={(props) => (
            <React.Suspense fallback={<FallbackLoader />}>
              <ConnectAcc {...props} />
            </React.Suspense>
          )}
        />

        <Route path="*" render={() => <div> Page Not Found </div>} />
      </Switch>
    </Router>
  );
};

const FallbackLoader = () => (
  <div className="page-loader-container">
    <div className="page-loader"></div>
  </div>
);

export default Routes;
