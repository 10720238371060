import React, { useCallback, useEffect, useRef, useState } from 'react'
import { fieldValidation } from '../../utils'
import ErrorText from '../error-text'
import LabelBox from '../label-box'
import PropertyUnit from '../product/property-unit'

const InputNumber = ({
  required,
  unit,
  label,
  isError = false,
  errorMessage,
  id,
  fieldName,
  onChange,
  placeholder = 'Type',
  subType,
  validations,
  showValidationMessage = false,
  infoPlacement,
  info,
  showSettings,
  onChangeSettings,
  defaultSettings,
  defaultValue,
  showAppliedSettings,
  onClickSettings,
  settingsValue,
  onDelete,
  readOnly = false,
  ...props
}) => {

  const [hasError, setHasError] = useState(isError)
  const [hasErrorMessage, setHasErrorMessage] = useState(errorMessage)
  const settings = useRef(null)

  // fieldValue is being used in case fieldName is passed
  const fieldValue = useRef(null)

  //updating state of on change of error & message
  useEffect(() => {
    setHasError(isError)
  }, [isError])

  useEffect(() => {
    setHasErrorMessage(errorMessage)
  }, [errorMessage])

  useEffect(() => {
    if (defaultValue && !fieldValue.current) {
      const defaultFieldValue = {
        [fieldName]: defaultValue,
        unit
      }

      if (defaultSettings) defaultFieldValue.settings = defaultSettings
      fieldValue.current = defaultFieldValue
    }
  }, [defaultSettings, defaultValue, fieldName, unit])

  const onChangeInput = useCallback(
    event => {
      const { value } = event.target;
      const { isValid, message } = fieldValidation(validations ? { ...validations, type: 'number' } : { type: 'number' }, value, label)

      if (!isValid) {
        if (!hasError) setHasError(true)
        if (message) setHasErrorMessage(message)
      } else {
        if (hasError) setHasError(false)
        if (hasErrorMessage) setHasErrorMessage('')
      }

      if (fieldName) {
        const argData = {
          [fieldName]: value,
          unit
        }

        fieldValue.current = argData

        if (settings?.current) argData.settings = settings?.current
        onChange &&
          onChange(
            argData,
            event
          )
      } else {
        onChange && onChange(value, unit, event)
      }
    },
    [fieldName, onChange, unit, hasError, hasErrorMessage, validations, label, settings]
  )

  const onChangeLabelSettings = useCallback(settingsData => {
    onChangeSettings && onChangeSettings(settingsData)
    settings.current = settingsData
    onChange && onChange(fieldValue.current ? { ...fieldValue.current, settings: settingsData } : { [fieldName]: null, unit: unit || null, settings: settingsData })
  }, [onChangeSettings, fieldValue, onChange, fieldName, unit])


  return (
    <div
      className={`input-number ${readOnly ? 'input-number-readonly' : ''} ${hasError || hasErrorMessage ? 'input-number-error' : ''} ${subType === 'phone' ? 'input-number-phone' : ''}`}
    >
      <LabelBox required={required} label={label} infoPlacement={infoPlacement} info={info} showSettings={showSettings} onChangeSettings={onChangeLabelSettings} defaultSettings={defaultSettings}
        showAppliedSettings={showAppliedSettings} onClickSettings={onClickSettings} settingsValue={settingsValue} onDelete={onDelete} />
      <div className='input-number-container'>
        <input {...props} defaultValue={defaultValue} placeholder={placeholder} id={id} type='number' onChange={onChangeInput} readOnly={readOnly} />
        {/* {unit && <div className='unit'>{unit}</div>} */}
        {unit && (
          <div className="unit">
            <PropertyUnit unit={unit} />
          </div>
        )}
      </div>
      {showValidationMessage && <ErrorText message={hasErrorMessage} />}
    </div>
  )
}

export default InputNumber
