import React, { useState, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import RegisterUser from "./register";
import LoginUser from "./login";

const Login = ({
  show,
  handleClose,
  showProjectMessage = false,
  signInSuccessCallback,
  onCheckUserAuthFail
}) => {
  const [showLogin, setShowLogin] = useState(true);

  const onHide = useCallback(
    (flag) => {
      if (handleClose) handleClose(flag);
      if (!showLogin) {
        // added timeout because view was getting immediately changed on close
        setTimeout(() => setShowLogin(true), 200);
      }
    },
    [showLogin, handleClose]
  );

  return (
    <Modal
      className="login-signup"
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <h2>{!showLogin ? "Sign up" : "Sign in"}</h2>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => onHide(false)}
        >
          <img src="/images/icon/close.svg" className="img-fluid" alt="close" />
        </button>
      </Modal.Header>
      <Modal.Body>
        {!showLogin ? (
          <>
            <RegisterUser signInSuccessCallback={signInSuccessCallback} />
            <div className="modal-footer">
              <p>
                Already have an account?{" "}
                <span>
                  <button
                    data-toggle="modal"
                    href="#"
                    onClick={() => setShowLogin(!showLogin)}
                    data-dismiss="modal"
                    style={{
                      color: "#000000",
                      border: "0",
                      background: "none",
                      textDecoration: "underline",
                      fontWeight: "600",
                    }}
                  >
                    Sign in
                  </button>
                </span>
              </p>
            </div>
          </>
        ) : (
          <div>
            <LoginUser signInSuccessCallback={signInSuccessCallback} onCheckUserAuthFail={onCheckUserAuthFail} />
            <div className="modal-footer">
              <p>
                New to Bimmatch?{" "}
                <span>
                  <button
                    data-toggle="modal"
                    href="#"
                    onClick={() => setShowLogin(!showLogin)}
                    data-dismiss="modal"
                    style={{
                      color: "#000000",
                      border: "0",
                      background: "none",
                      textDecoration: "underline",
                      fontWeight: "600",
                    }}
                  >
                    Sign up
                  </button>
                </span>
              </p>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Login;
