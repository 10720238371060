import React, { useEffect, useState } from "react";
import { deleteCookie, getCookie } from "../../utils";
import Login from "../login";
import Modal from "../modal";

export function InactivityLogout() {
  const [loggedOut, setLoggedOut] = useState(false);
  const [isSignInVisible, setIsSignInVisible] = useState(false);

  useEffect(() => {
    const shouldLogout = getCookie("__bauhub_logout");
    if (shouldLogout === "true") {
      setLoggedOut(true);
    }
  }, []);

  const removeSignInAgainPopup = () => {
    deleteCookie("__bauhub_logout");
    setIsSignInVisible(false);
    setLoggedOut(false);
  };

  if (isSignInVisible) {
    return (
      <Login
        show={isSignInVisible}
        handleClose={removeSignInAgainPopup}
        signInSuccessCallback={removeSignInAgainPopup}
      />
    );
  }

  return (
    <div
      id="inactivity-logout-popup-wrapper"
      className={`${loggedOut ? "show" : "hide"}`}
    >
      {loggedOut && (
        <Modal
          size="medium"
          visible={loggedOut}
          closable={true}
          title={"Safe and secure"}
          confirmButtonName="SIGN IN"
          cancelButtonDisable={true}
          onClickConfirm={() => setIsSignInVisible(true)}
          cancelable={false}
          onClose={() => setLoggedOut(false)}
        >
          <div className="modal-body-wrapper">
            <p>
              We logged you out to keep your content protected, while you’ve
              been away...
            </p>
            <img src="/images/icon/sleep.svg" alt="cmp1" />
          </div>
        </Modal>
      )}
    </div>
  );
}
