import React from "react";
import Select from "../select";

const SelectBox = ({ label = '', infoPlacement, info, alignLabelHorizontally = false, ...props }) => {
  return (
    <div className={'selectBox'}>
      <div className="selectBox-container">
        <Select hideLabel={!label} searchIcon="/images/icon/search-black.svg" {...props} showOptions={true} isSelectBox={true} label={label} infoPlacement={infoPlacement} info={info}/>
      </div>
    </div>
  );
};

export default SelectBox;
