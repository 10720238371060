import React, { useCallback, useEffect, useRef, useState } from "react";
import ErrorText from "../error-text";
import LabelBox from "../label-box";

const Checkbox = ({
  id,
  required = false,
  label,
  isError = false,
  errorMessage,
  hideLabel = false,
  onChange,
  defaultValue,
  fieldName,
  showValidationMessage = false,
  infoPlacement,
  info,
  showSettings,
  onChangeSettings,
  defaultSettings,
  showAppliedSettings,
  onClickSettings,
  settingsValue,
  onDelete,
  value,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [defaultValueSetStatus, setDefaultValueSetStatus] = useState(false)
  const settings = useRef(null)
  // fieldValue is being used in case fieldName is passed
  const fieldValue = useRef(null)

  useEffect(() => {
    if (!value && !defaultValueSetStatus && typeof defaultValue === 'boolean') {
      setDefaultValueSetStatus(true)
      setIsChecked(defaultValue)
      const defaultFieldValue = {
        [fieldName]: defaultValue
      }

      if (defaultSettings) defaultFieldValue.settings = defaultSettings
      fieldValue.current = defaultFieldValue
    }
  }, [defaultSettings, defaultValue, defaultValueSetStatus, fieldName, value])

  useEffect(() => {
    if (value) {
      setIsChecked(value)
    }
  }, [value])

  const onClickCheckbox = useCallback(() => {
    const changedValue = !isChecked;
    setIsChecked(changedValue);
    if (fieldName) {
      const argData = { [fieldName]: changedValue }
      if (settings.current) argData.settings = settings.current
      fieldValue.current = argData
      onChange && onChange(argData);
    } else {
      onChange && onChange(changedValue);
    }
  }, [isChecked, onChange, fieldName, settings]);

  const onChangeLabelSettings = useCallback(settingsData => {
    onChangeSettings && onChangeSettings(settingsData)
    settings.current = settingsData
    onChange && onChange(fieldValue.current ? { ...fieldValue.current, settings: settingsData } : { [fieldName]: null, settings: settingsData })
  }, [onChangeSettings, fieldValue, onChange, fieldName])

  return (
    <div
      {...props}
      className={`checkbox ${!isError ? "checkbox-normal" : "checkbox-error"}`}
    >
      <div className="checkbox-container">
        <div id={id} className="checkbox-main" onClick={onClickCheckbox}>
          {isChecked && <div className="checkbox-selected"></div>}
        </div>
        {!hideLabel && <LabelBox required={required} label={label} infoPlacement={infoPlacement} info={info} showSettings={showSettings} onChangeSettings={onChangeLabelSettings} defaultSettings={defaultSettings} showAppliedSettings={showAppliedSettings} onClickSettings={onClickSettings} settingsValue={settingsValue} onDelete={onDelete}/>}
      </div>
      {showValidationMessage && <ErrorText message={errorMessage} />}
    </div>
  );
};

export default Checkbox;
