import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import debounce from "lodash.debounce";
import Login from "../../components/login";
import AuthContext, { withAuth } from "../contexts/auth";
import { withTracking } from "../contexts/tracking";
import { Avatar, Popover, Toast } from "../../components";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { useResetAtom } from "jotai/utils";
import {
  allProductPropertiesState,
  authState,
  checkUserAuthState,
  hasCreatedNewUserAuthState,
  organizationState,
  permissionsState,
  planDetailsState,
  userFeaturesPermissionsState,
  userState,
} from "../../atoms";
import { getCookie, setCookie } from "../../utils";
import { useAtomValue } from "jotai";
import { CONSTANTS } from "../../constants";
import { commonAuthDeleteCookies } from "../auth/utils";

const unitTypes = [{
  id: 'metric', displayName: 'Metric Units'
}, {
  id: 'imperial', displayName: 'Imperial Units'
}]

const Header = ({
  tracking,
  className,
  auth,
  isLeftMenuOpen,
  handleLeftMenuOpenCallback,
}) => {

  const defaultUnit = unitTypes.find(unitData => unitData.id === (localStorage.getItem('unit') || 'metric'))

  // local states
  const [displaySignIn, setDisplaySignIn] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [toastData, setToastData] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(defaultUnit)

  const headerRef = useRef(null);
  const history = useHistory();
  const location = useLocation();

  // global states
  const { isVendor, isPlanner, id: orgId } = useRecoilValue(organizationState);
  const { organizations = [] } = useRecoilValue(userState);
  const resetOrganization = useResetRecoilState(organizationState);
  const resetUser = useResetRecoilState(userState);
  const resetPlanDetails = useResetRecoilState(planDetailsState);
  const resetAuthState = useResetAtom(authState);
  const resetCheckUserAuth = useResetAtom(checkUserAuthState);
  const resetHasCreatedNewUserAuth = useResetAtom(hasCreatedNewUserAuthState);
  const resetAllProductPropertiesState = useResetAtom(allProductPropertiesState)
  const userFeaturesPermissions = useAtomValue(userFeaturesPermissionsState);

  const { userRoles = [] } = useAtomValue(permissionsState);

  const makeHeaderSticky = debounce(() => {
    const scroll = window.pageYOffset;
    if (scroll >= 30 && !sticky) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  }, 5);

  useEffect(() => {
    window.addEventListener("scroll", makeHeaderSticky);
    return () => {
      window.removeEventListener("scroll", makeHeaderSticky);
    };
  }, [makeHeaderSticky]);

  const displaySignInHandler = (bool) => {
    setDisplaySignIn(bool);
  };

  const onLogout = useCallback(() => {
    displaySignInHandler(false);

    const logInMode = getCookie("__login_mode");

    if (logInMode === "google") {
      auth.apis.signOut();
    }
    commonAuthDeleteCookies()

    localStorage.clear();

    // resetting necessary global states
    resetOrganization();
    resetUser();
    resetPlanDetails();
    resetAuthState();
    resetCheckUserAuth();
    resetHasCreatedNewUserAuth();
    resetAllProductPropertiesState()
    history.push("/products");
  }, [auth.apis, resetOrganization, resetUser, resetPlanDetails, resetAuthState, resetCheckUserAuth, resetHasCreatedNewUserAuth, resetAllProductPropertiesState, history]);

  const isMasterOrganization = useMemo(() => isPlanner || isVendor, [isPlanner, isVendor]);

  const showOrganizationAccountTab = useMemo(() => isMasterOrganization && !!userFeaturesPermissions.permissions[CONSTANTS.PERMISSIONS.ORGANIZATION_ACCOUNT_VIEW], [isMasterOrganization, userFeaturesPermissions]);

  const { isAuthenticated } = useContext(AuthContext);

  const successCallback = useCallback(() => {
    setDisplaySignIn(false);
  }, []);

  const onCheckUserAuthFail = useCallback((errorData) => {
    if (errorData) {
      setToastData([
        {
          id: `singin-toast`,
          title: "Oops",
          description: errorData?.message || "Some error occured while sign in",
          type: "error",
        },
      ]);
    }
  }, []);

  const onChangeOrganization = useCallback((id) => {
    resetAllProductPropertiesState();
    setCookie("__current_organization", id);
    history.push('/products')
    history.go(0)
  }, [history, resetAllProductPropertiesState]);

  const onSelectUnit = useCallback(unitData => {
    if (selectedUnit.id !== unitData.id) {
      setSelectedUnit(unitData)
      localStorage.setItem("unit", unitData.id);
      history.go(0)
    }
  }, [selectedUnit, history])

  return (
    <>
      <div
        className={`header ${className ? className : sticky ? "stick" : ""}`}
        ref={headerRef}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="menu-row">
                <div className="logo-wrap d-none d-lg-block">
                  <a href="https://www.bimmatch.com/">
                    <img alt="logo" src="/images/bimmatch/logo-header.svg" />
                  </a>
                </div>
                <div className="logo-wrap d-lg-none">
                  <a href="https://www.bimmatch.com/">
                    <img
                      alt="logo"
                      src="/images/bimmatch/Logo_Header_Mobile.svg"
                    />
                  </a>
                </div>
                <div className="menu-wrap">
                  <div className="menu-right">
                    <Popover
                      content={<UnitsPopoverContent onSelectUnit={onSelectUnit} selectedUnit={selectedUnit.id} />}
                      placement="bottom"
                      className='units-popover'
                    >
                      <div className="unit-container">
                        <img className="unit-icon" alt="unit" src={`/images/icon/${selectedUnit.id}-unit.svg`} /> <span className="unit-name">{selectedUnit.displayName}</span>
                      </div>
                    </Popover>
                    {!(auth && auth.isAuthenticated) && (

                      <>
                        <div className="button-wrap">
                          <button
                            className="btn btn-outline-secondary"
                            onClick={(e) => {
                              e.preventDefault();
                              displaySignInHandler(true);
                            }}
                          >
                            SIGN IN
                          </button>
                          <Login
                            show={displaySignIn}
                            handleClose={displaySignInHandler}
                            signInSuccessCallback={successCallback}
                            onCheckUserAuthFail={onCheckUserAuthFail}
                          />
                        </div>
                      </>
                    )}
                    {auth && auth.isAuthenticated && (
                      <>
                        {/* <div className="button-wrap">
                        <Notification num={5} />
                      </div> */}
                        <div className="button-wrap">
                          <div className="profile">
                            <Popover
                              content={
                                <ProfilePopoverContent
                                  onClickLogout={onLogout}
                                  onClickOrgAccount={() => {
                                    history.push("/settings/organization");
                                  }}
                                  onClickMyAccount={() => {
                                    history.push("/settings/personal");
                                  }}
                                  organizations={organizations}
                                  showOrganizationAccountTab={showOrganizationAccountTab}
                                  currentOrgId={orgId}
                                  userRoles={userRoles}
                                  onChangeOrganization={onChangeOrganization}
                                  isMasterOrganization={isMasterOrganization}
                                />
                              }
                              hideArrow={true}
                              placement="bottom"
                            >
                              <Avatar
                                url={auth?.user?.photoURL}
                                firstName={
                                  auth.user.displayName === null
                                    ? localStorage.getItem("username") === null
                                      ? auth.user.email.split("@")[0]
                                      : localStorage.getItem("username")
                                    : auth.user.displayName
                                }
                              />
                            </Popover>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="menu-left">
                    <div
                      id="nav_list"
                      className="menu-mobile-icon"
                      onClick={(e) =>
                        handleLeftMenuOpenCallback(!isLeftMenuOpen)
                      }
                    >
                      <div
                        id="toggle-icon"
                        className={isLeftMenuOpen ? "open" : ""}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                    <div className={`main-navigation pushmenu pushmenu-left ${isLeftMenuOpen ? "pushmenu-open" : ""}`}>
                      <nav id="nav" className="nav">
                        <ul className="main-menu-list">
                          <li>
                            <Link
                              to="/products"
                              onClick={() => {
                                tracking && tracking.track("Products List");
                              }}
                              className={
                                location.pathname === "/products"
                                  ? "active"
                                  : ""
                              }
                            >
                              PRODUCTS &amp; MATERIALS
                            </Link>
                          </li>
                          {!!userFeaturesPermissions.features[
                            CONSTANTS.FEATURES.PROJECT_VIEW
                          ] &&
                            (isAuthenticated
                              ? !!userFeaturesPermissions.permissions[
                              CONSTANTS.PERMISSIONS.PROJECT_VIEW
                              ]
                              : true) && (
                              <li>
                                <Link
                                  to="/projects"
                                  onClick={() => {
                                    tracking && tracking.track("Projects List");
                                  }}
                                  className={
                                    location.pathname === "/projects"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  MY PROJECTS
                                </Link>
                              </li>
                            )}
                          {/* {auth &&
                            auth.isAuthenticated &&
                            isMasterOrganization && (
                              <li>
                                <Link
                                  to="/specs"
                                  className={
                                    location.pathname === "/specs"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  SPECS
                                </Link>
                              </li>
                            )} */}
                          {isMasterOrganization &&
                            !!userFeaturesPermissions.permissions[
                            CONSTANTS.PERMISSIONS.ANALYTICS_VIEW
                            ] && (
                              <li>
                                <Link
                                  to="/analytics"
                                  className={
                                    location.pathname === "/analytics"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  ANALYTICS
                                </Link>
                              </li>
                            )}

                          {auth &&
                            auth.isAuthenticated &&
                            isMasterOrganization &&
                            !!userFeaturesPermissions.permissions[
                            CONSTANTS.PERMISSIONS.TEAM_VIEW
                            ] && (
                              <li>
                                <Link
                                  to="/team"
                                  className={
                                    location.pathname === "/team"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  TEAM
                                </Link>
                              </li>
                            )}

                          {/* <li>
                          <Link
                            to="/"
                          onClick={() => {
                            tracking && tracking.track("Projects List");
                          }}
                          >
                            TEAM CHATS
                          </Link>
                        </li> */}
                          {auth &&
                            auth.isAuthenticated &&
                            isMasterOrganization &&
                            !!userFeaturesPermissions.permissions[
                            CONSTANTS.PERMISSIONS.ORG_LIB_VIEW
                            ] && (
                              <li>
                                <Link
                                  to="/organization-items"
                                  className={
                                    location.pathname === "/organization-items"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  ORGANIZATION DB
                                </Link>
                              </li>
                            )}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toast
        toastList={toastData}
        position={"bottom-right"}
        autoDeleteTime={toastData?.duration || 12000}
      />
    </>
  );
};

const ProfilePopoverContent = ({
  onClickMyAccount,
  onClickOrgAccount,
  onClickLogout,
  showOrganizationAccountTab,
  organizations,
  currentOrgId,
  userRoles,
  onChangeOrganization,
  isMasterOrganization
}) => {
  const onClickItems = useCallback(
    (event, type) => {
      event.stopPropagation();
      if (type === "my-account") {
        onClickMyAccount && onClickMyAccount();
      } else if (type === "org-account") {
        onClickOrgAccount && onClickOrgAccount();
      } else {
        onClickLogout && onClickLogout();
      }
    },
    [onClickMyAccount, onClickOrgAccount, onClickLogout]
  );

  const onClickOrganizationTab = useCallback(
    (event, orgId) => {
      event.stopPropagation();
      if (currentOrgId !== orgId)
        onChangeOrganization && onChangeOrganization(orgId);
    },
    [currentOrgId, onChangeOrganization]
  );

  return (
    <div
      className="profile-popover-content"

    >
      <div onClick={(e) => onClickItems(e, "my-account")} className="profile-content-item">
        <img src={"/images/icon/user-account.svg"} alt="My account" />{" "}
        <span>My account</span>
      </div>

      <hr />

      <div className="organizations-list" >
        {organizations.map((organization) => (
          <div
            key={organization.id}
            className={`organization-details ${(organization.id === currentOrgId) ? "organization-details-active" : ""}`}
            onClick={(event) => onClickOrganizationTab(event, organization.id, organization)}
          >
            <div className="organization-name">
              {organization?.displayName || (<span className='no-org-name'>{isMasterOrganization ? 'Missing organization name' : 'No organization'}</span>)}
            </div>
            {!!organization?.roles?.length && (
              <div className="organization-roles">
                {userRoles
                  .filter((role) => organization?.roles.includes(role.id))
                  .map((role) => (
                    <p key={role.id} className="organization-role-name">
                      {role?.displayName}
                    </p>
                  ))}
              </div>
            )}
          </div>
        ))}
      </div>

      <hr />

      {showOrganizationAccountTab && (
        <>
          <div
            className="profile-content-item"
            onClick={(e) => onClickItems(e, "org-account")}
          >
            <img src={"/images/icon/suitcase.svg"} alt="Organization account" />{" "}
            <span>Organization account</span>
          </div>
          <hr />
        </>
      )}

      <div
        className="profile-content-item"
        onClick={(e) => onClickItems(e, "logout")}
      >
        <img src={"/images/icon/logout.svg"} alt="Log out" />{" "}
        <span>Log out</span>
      </div>
    </div>
  );
};

const UnitsPopoverContent = ({ selectedUnit, onSelectUnit }) => {



  return (
    <div className="units-list-container">
      {unitTypes.map(unitData => <div className={`single-unit-container ${selectedUnit === unitData.id ? 'selected-unit' : ''}`} key={unitData.id} onClick={() => onSelectUnit && onSelectUnit(unitData)}><img className="unit-icon" alt="unit" src={`/images/icon/${unitData.id}-unit.svg`} /> <span className="unit-name">{unitData.displayName}</span></div>)}
    </div>
  )

}

export default withTracking(withAuth(Header));
