import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import AuthContext from "./common/contexts/auth";

const PrivateRoute = ({authAccess = true, ...props}) => {
  const { isAuthenticated } = useContext(AuthContext);

  return isAuthenticated && authAccess ? (
    <Route {...props} />
  ) : (
    <Redirect
      to={{
        pathname: "/products",
      }}
    />
  );
};

export default PrivateRoute;
