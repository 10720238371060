import React, { useCallback, useState, memo, useEffect, useMemo } from "react";
import TableRow from "./table-row";
import TableHeader from "./table-header-row";

const Table = ({ tableRef, columns = [], data = [], onRowClick, handleSort, expandedRows: parentExpandedRows, selectedRows: parentSelectedRows, config: changedConfig, openRowAsync, onExpansionSuccess, onExpansionFailure, isLoading }) => {
  const [columnWidths, setColumnWidths] = useState({});
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [selectedRows, setSelectedRows] = useState(new Set());

  const config = useMemo(() => ({ ...defaultConfig, ...(changedConfig || {}) }), [changedConfig])
  const showAdditionalHeader = useMemo(() => (isLoading ? (config.showAdditionalHeaderOnLoading && config.hasAdditionalHeader) : config.hasAdditionalHeader), [isLoading, config])
  const showHeader = useMemo(() => (!isLoading || config.showHeaderOnLoading), [isLoading, config])

  const handleColumnResize = useCallback((key, newWidth) => {
    setColumnWidths((prevWidths) => ({
      ...prevWidths,
      [key]: newWidth,
    }));
  }, []);

  const sortData = useCallback((columnName) => {
    let newSortDirection = sortDirection === "asc";
    if (sortBy === columnName) {
      newSortDirection = (sortDirection === "asc") ? "desc" : "asc";
    } else {
      setSortBy(columnName);
    }
    setSortDirection(newSortDirection);
    if (handleSort) {
      handleSort(columnName, newSortDirection);
    }
  }, [handleSort, sortDirection, sortBy]);

  const resizeHandler = useCallback((column) => (e) => {
    const initialX = e.clientX;
    const initialWidth = columnWidths[column.id] || 0;

    const handleMouseMove = (e) => {
      const newWidth = initialWidth + (e.clientX - initialX);
      handleColumnResize(column.id, newWidth);
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  }, [handleColumnResize, columnWidths]);

  const toggleExpandedRowStateData = useCallback((id) => {
    if (expandedRows.has(id)) {
      setExpandedRows((oldExpandedIds) => {
        const set = new Set(oldExpandedIds);
        set.delete(id);
        return set;
      });
    } else {
      setExpandedRows(
        (oldExpandedIds) => new Set([...oldExpandedIds, id])
      );
    }
  }, [expandedRows])


  useEffect(() => {
    if (Array.isArray(parentExpandedRows)) {
      setExpandedRows(new Set(parentExpandedRows))
    }
  }, [parentExpandedRows])

  useEffect(() => {
    if (Array.isArray(parentSelectedRows)) {
      setSelectedRows(new Set(parentSelectedRows))
    }
  }, [parentSelectedRows])


  return (
    <div className={`bimmatch-table-container ${config.stickyHeader ? "sticky-header" : ""}`} ref={tableRef}>
      <table>
        <colgroup>
          {columns.map((column) => (
            <col
              key={column.id}
              style={{ width: columnWidths[column.id] || "auto" }}
              className={`colgroup-${column.id} ${column.className || ""}`}
            />
          ))}
        </colgroup>
        <thead>
          {showAdditionalHeader && <TableHeader columns={columns} config={config} resizeHandler={resizeHandler} sortBy={sortBy} sortData={sortData} sortDirection={sortDirection} isAdditionalHeader />}
          {showHeader && <TableHeader columns={columns} config={config} resizeHandler={resizeHandler} sortBy={sortBy} sortData={sortData} sortDirection={sortDirection} />}
        </thead>
        <tbody>
          {
            isLoading ? (
              <tr>
                <td colSpan={columns.length}>
                  <div className="page-loader table-data-loading"></div>
                </td>
              </tr>
            ) : (
              <>
                {data.map((row) => (
                  <TableRow
                    key={row.id}
                    columns={columns}
                    row={row}
                    onRowClick={onRowClick}
                    expandedRows={expandedRows}
                    selectedRows={selectedRows}
                    onExpansionFailure={onExpansionFailure}
                    onExpansionSuccess={onExpansionSuccess}
                    openRowAsync={openRowAsync}
                    toggleExpandedRowStateData={toggleExpandedRowStateData}
                    config={config}
                  />
                ))}
              </>
            )
          }
        </tbody>
      </table>
    </div>
  );
};

const defaultConfig = {
  sortable: false,
  resizable: false,
  expandable: false,
  leftFreezed: false,
  rightFreezed: false,
  stickyHeader: false,
  hasAdditionalHeader: false,
  asyncRowExpansion: false,
  showHeaderOnLoading: true,
  showAdditionalHeaderOnLoading: true,
}

export default memo(Table);
