import React, { useCallback, useEffect, useState } from "react";
import Popover from "../popover";
import Select from "../select";
import Toggle from "../toggle";



const standardOptions = [
  { id: 'optional', displayName: "Optional" },
  { id: 'required', displayName: "Required" },
]

const lodOptions = [
  { id: '100', displayName: "100" },
  { id: '200', displayName: "200" },
  { id: '300', displayName: "300" },
  { id: '350', displayName: "350" },
  { id: '400', displayName: "400" },
  { id: '500', displayName: "500" },
]

const getSettingClassname = (settingsData = {}) => {
  let settingsClassname = ''
  let isRequired = settingsData?.standard === 'required'

  if (settingsData?.lod && isRequired && settingsData?.instruction) {
    settingsClassname = 'settings-standard-lod-instruction'
  } else if (settingsData?.lod && isRequired) {
    settingsClassname = 'settings-standard-lod'
  } else if (settingsData?.lod && settingsData?.instruction) {
    settingsClassname = 'settings-lod-instruction'
  } else if (isRequired && settingsData?.instruction) {
    settingsClassname = 'settings-standard-instruction'
  } else if (settingsData?.lod) {
    settingsClassname = 'settings-lod'
  } else if (isRequired) {
    settingsClassname = 'settings-standard'
  } else if (settingsData?.instruction) {
    settingsClassname = 'settings-instruction'
  }

  return settingsClassname

}

const LabelBox = ({ label = "Label Text", required = false, infoPlacement = 'top', info, showSettings = false, onChangeSettings, defaultSettings, showAppliedSettings, onClickSettings, showSettingsPopover = false, settingsValue, onDelete }) => {

  const [isSettingsPopoverOpen, setIsSettingsPopoverOpen] = useState(false)
  const [settingsData, setSettingsData] = useState({ lod: null, standard: null })

  const onClickSettingsPopover = useCallback(() => {
    setIsSettingsPopoverOpen(oldState => !oldState)
    onClickSettings && onClickSettings()
  }, [onClickSettings])

  const onClickOutsideSettingsPopover = useCallback(() => {
    setIsSettingsPopoverOpen(false)
  }, [])

  // saving value of settings
  useEffect(() => {
    if (settingsValue) {
      setSettingsData(settingsValue)
    }
  }, [settingsValue])

  // saving default settings
  useEffect(() => {
    if (defaultSettings && !settingsData?.lod && !settingsData?.standard) {
      setSettingsData(defaultSettings)
    }
  }, [settingsData, defaultSettings])

  const onChangeLodStandard = useCallback(fieldData => {
    const updatedSettings = { ...settingsData, ...fieldData }
    setSettingsData(updatedSettings)
    onChangeSettings && onChangeSettings(updatedSettings)
  }, [settingsData, onChangeSettings])

  return (
    <div className={`label-container ${showSettings ? 'label-with-settings' : (onDelete ? 'label-with-delete' : '')}  ${isSettingsPopoverOpen ? 'settings-open' : ''}`} >
      <div className={`label-text-container ${getSettingClassname(settingsData)}`}>
        <div className="label-text">{required && <span className="form-required">*</span>} {label}</div>
        {info && (
          <Popover
            className='info-label-popover'
            showOnHover={true}
            content={<div className='info-label'>{info || 'Info about label'}</div>}
            placement={infoPlacement}
            theme='black'
          >
            <img src={'/images/icon/info-circle.svg'} alt='info' />
          </Popover>
        )}
      </div>
      <div className='label-extras'>
        {((showSettings || showAppliedSettings) && (!isSettingsPopoverOpen || !showSettingsPopover ) && (settingsData?.lod || settingsData?.standard || settingsData?.instruction)) && (
          <div className='lod-with-required'>
            {settingsData?.lod && <div className='lod-count'>{settingsData?.lod}</div>} 
            {settingsData?.standard === 'required' && <img className='required-mark' src={'/images/icon/mandatory.svg'} alt='required'/>}
            {settingsData?.instruction && <img className='instruction-mark' src={'/images/icon/info.svg'} alt='instruction'/>}
          </div>
        )}
        {showSettings && showSettingsPopover && (
          <Popover
            className='lod-standard-popover'
            content={<div className='label-settings-options'>
              <Toggle value={settingsData?.standard} fieldName={'standard'} label={'Organization standard'} data={standardOptions} onChange={onChangeLodStandard} />
              <Select value={settingsData?.lod} fieldName={'lod'} label={'LOD'} options={lodOptions} onSelect={onChangeLodStandard} />
            </div>}
            placement={'bottom'}
            onClick={onClickSettingsPopover}
            onClickOutside={onClickOutsideSettingsPopover}
          >
            <img className='label-setting' src={'/images/icon/settings.svg'} alt='info' />
          </Popover>
        )}
        {/* both delete and settings can't be enabled together */}
        {showSettings && !showSettingsPopover && <img className='label-setting' src={'/images/icon/settings.svg'} alt='settings' onClick={onClickSettingsPopover}/>}
        {onDelete && !showSettings && <img className='label-delete' src={'/images/icon/trash.svg'} alt='trash' onClick={onDelete && onDelete}/>}
        
      </div>
    </div>
  );
};

export default LabelBox;
