import React, { useRef, useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import "firebaseui/dist/firebaseui.css";
import { useMutation } from "@apollo/client";
import { useAtom } from 'jotai'
import Button from "../button";
import { useTracking } from "../../common/contexts/tracking";
import { getFirebaseInstance } from "../../common/firebase";
import { CHECK_USER } from "../../graphqlQueries";
import { checkUserAuthState } from "../../atoms";
import { autodeskAuthUrl, setCookie, windowPopupCenter } from "../../utils";
import { accessTokenExpiryTime, commonAuthTracking } from "../../common/auth/utils";

const LoginUser = ({ signInSuccessCallback, onCheckUserAuthFail }) => {

  //local states
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState();
  const firebaseuiRef = useRef(null);

  const { register, handleSubmit } = useForm();
  const tracking = useTracking();


  //global states
  const [checkUserAuth, setCheckUserAuth] = useAtom(checkUserAuthState)

  //API functions
  const [checkUser] = useMutation(CHECK_USER);

  const { REACT_APP_AUTODESK_AUTH_CLIENT_ID, REACT_APP_AUTODESK_AUTH_REDIRECT_URI } = process.env

  useEffect(() => {
    const firebase = getFirebaseInstance();
    const uiConfig = {
      signInFlow: "popup",
      signInOptions: firebase.providers,
      privacyPolicyUrl: "/policy/terms-and-conditions",
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
          if (!checkUserAuth?.status) {
            onCheckUserAuthFail && onCheckUserAuthFail(checkUserAuth)
          }

          if (authResult && authResult.additionalUserInfo.isNewUser) {
            commonAuthTracking({ tracking, user: authResult.user, type: "Sign In"})
          }

          signInSuccessCallback && signInSuccessCallback(authResult, redirectUrl)
          return false;
        },
        uiShown: () => { },
      },
    };
    firebaseuiRef &&
      firebaseuiRef.current &&
      firebase.ui.start("#firebaseui-auth-container", uiConfig);

  }, [signInSuccessCallback, tracking, checkUserAuth, onCheckUserAuthFail]);

  const handleLogin = useCallback(async (data) => {
    const firebaseInstance = getFirebaseInstance();

    try {
      let checkUserData = checkUserAuth
      const dataEmail = data.email.toLowerCase()
      if (!checkUserAuth) {
        // checking if user already exists in DB or not
        const checkUserResponse = await checkUser({
          variables: {
            logInMode: 'google',
            email: dataEmail
          }
        })
        checkUserData = checkUserResponse?.data?.checkUser
        setCheckUserAuth(checkUserData)
      }

      if (!checkUserData?.status) {
        throw new Error(checkUserData?.message)
      }

      const userCredential = await firebaseInstance.auth.signInWithEmailAndPassword(
        dataEmail,
        data.password
      );
      const user = userCredential.user;
      const token = await user.getIdToken();
      setCookie("__login_mode", 'google');
      setCookie("__bauhub_token", token, accessTokenExpiryTime);
      commonAuthTracking({ tracking, user , type: "Sign In"})
      signInSuccessCallback && signInSuccessCallback(user);
    } catch (err) {
      console.log('log in with google error.', err.message);
      setError(err.message);
    }
  }, [checkUser, checkUserAuth, tracking, setCheckUserAuth, signInSuccessCallback])

  const onClickAutodesk = useCallback(event => {
    event.stopPropagation()
    windowPopupCenter({ url: `${autodeskAuthUrl}/authorize?response_type=code&client_id=${REACT_APP_AUTODESK_AUTH_CLIENT_ID}&redirect_uri=${REACT_APP_AUTODESK_AUTH_REDIRECT_URI}&scope=data:read`, title: 'autodeskAuthWindow', width: 500, height: 700 })
  }, [REACT_APP_AUTODESK_AUTH_CLIENT_ID, REACT_APP_AUTODESK_AUTH_REDIRECT_URI])



  return (
    <>
      <div className="login-container">
        <div className="left">
          <p style={{ textAlign: "center", marginBottom: "17px" }}>
            Sign in with your Bimmatch account
          </p>
          <form onSubmit={handleSubmit(handleLogin)}>
            <div className="form-group">
              <input
                name="email"
                className="form-control input-two"
                type="email"
                placeholder="Email *"
                ref={register({ required: true })}
              />
            </div>
            <div className="form-group eye">
              <input
                name="password"
                className="form-control input-two"
                type={showPassword ? "text" : "password"}
                placeholder="Password *"
                ref={register({ required: true })}
                autoComplete="off"
              />
              <i
                className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
                aria-hidden="true"
                onClick={() => setShowPassword(!showPassword)}
              ></i>
            </div>
            <div className="form-group">
              <button
                type="submit"
                className="blue-bg-button w-100 colorBotton"
              >
                SIGN IN
              </button>
              {error && (
                <p style={{ display: "flex" }} className="registerError">
                  {error}
                </p>
              )}
            </div>
          </form>
        </div>
        <div className="center">
          <div>OR</div>
          <div className="divider"></div>
        </div>
        <div className="right">
          <p style={{ textAlign: "center" }}>
            Log in with your network account
          </p>

          <Button onClick={onClickAutodesk} id='autodesk-login-button'><><img src={'../../images/icon/autodesk.svg'} alt="autodesk" />LOG IN WITH AUTODESK</></Button>

          <div
            id="firebaseui-auth-container"
            className="firebaseui-auth-container"
            ref={firebaseuiRef}
          ></div>
        </div>
      </div>
      <div className="login-notice">
        {"By submitting your details you agree to our "}
        <a
          href="https://www.bimmatch.com/terms-of-use"
          alt="Terms and Conditions"
          rel="noopener noreferrer"
          target="_blank"
        >
          Terms of Use
        </a>
        {" and "}
        <a
          href="https://www.bimmatch.com/privacy-policy"
          alt="Privacy Policy"
          rel="noopener noreferrer"
          target="_blank"
        >
          Privacy Policy
        </a>
      </div>
    </>
  );
};

export default LoginUser;
