import React from 'react';
import Chip from '../chip';

// --Not a reusable component--
const SelectType = ({ totalSelectedData, filterData, searchInput, onDeselectOption, searchData, onSelectOption }) => {
    return (
      <div className='select-open-container'>
        <div className='select-open-main'>
          {!!totalSelectedData.length &&
            filterData(totalSelectedData).map(chipData => (
              <Chip
                key={chipData?.id + '-black-head-data'}
                highlightText={searchInput}
                name={chipData?.displayName}
                darkMode={true}
                onClickChip={() => onDeselectOption(chipData)}
                iconSrc={chipData.image || ''}
              />
            ))}
  
          {!!searchData?.length &&
            filterData(searchData)
              .filter(searchFilterData =>
                totalSelectedData.length
                  ? !totalSelectedData
                    .map(selectedChipData => selectedChipData.id)
                    .includes(searchFilterData.id)
                  : true
              )
              .map(chipData => (
                <Chip
                  key={chipData?.id}
                  highlightText={searchInput}
                  name={chipData?.displayName}
                  onClickChip={() => onSelectOption(chipData)}
                  iconSrc={chipData.image || ''}
                />
              ))}
        </div>
      </div>
    )
  }

  export default SelectType