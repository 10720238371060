import React, { useEffect, useState } from "react";

export default function Alert(props) {
  const { text, show, children } = props;
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    setShowAlert(show);
  }, [show]);

  return showAlert ? (
    <div className="bimmatch-alert">
      {children ? children : <p>{text}</p>}
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={() => {
          setShowAlert(false);
        }}
      >
        <img
          src="/images/icon/close-white.svg"
          style={{ width: "20px", display: "block" }}
          alt="close"
        />
      </button>
    </div>
  ) : (
    <></>
  );
}
