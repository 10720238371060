import React from "react";

const FloatingPanel = ({
  direction = "right",
  open = false,
  title,
  children,
  onClose,
  withScroll = true
}) => {
  return (
    <div
      className={`bimmatch-floating-panel ${direction}-panel ${open ? "panel-open" : ""}`}
    >
      <div className="panel-header">
        <img src="/images/icon/floating-panel.svg" alt="floating panel" />
        {!!title && <h2 className="title">{title}</h2>}
        <img
          src="/images/icon/close.svg"
          alt="close btn"
          className="close-btn"
          onClick={onClose}
        />
      </div>
      <div className={`panel-content ${withScroll ? "scroll-content" : ""}`}>{children ? children : (<></>)}</div>
    </div>
  );
};

export default FloatingPanel;
