import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { autodeskAuthState } from "../../atoms";
import { useMutation } from "@apollo/client";
import { CONNECT_TO_ACC } from "../../graphqlQueries";
import useOrganization from "../../hooks/useOrganization";

const ConnectAcc = () => {
  const history = useHistory();
  const setAuthTemp = useSetRecoilState(autodeskAuthState);
  const [connectToAcc] = useMutation(CONNECT_TO_ACC);
  const { refreshOrganization } = useOrganization();
  const mountedRef = useRef();

  useEffect(() => {
    if (mountedRef.current) return;
    mountedRef.current = true;
    if (history && history?.location && history?.location?.hash) {
      const hashData = history.location.hash;
      // handling error
      if (hashData.includes("error")) {
        window.close();
        return;
      }

      let access_token = hashData.match(/#(?:access_token)=([\S\s]*?)&/)[1];
      connectToAcc({
        variables: {
          token: access_token,
        },
      })
        .then(async (res) => {
          const { message, status } = res?.data?.connectToAcc || {};
          window.opener.postMessage({
            status,
            message,
            service: "connect-to-acc",
          });
          window.close();
        })
        .catch(alert);
    }
  }, [history, setAuthTemp, connectToAcc, refreshOrganization]);
  return <div>Connecting...</div>;
};

export default ConnectAcc;
