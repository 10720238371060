import { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_ALL_HOUSES } from "../graphqlQueries";
import { useRecoilValue } from "recoil";
import { useAtom } from "jotai";

import useGetFacetsData from "./useGetFacetsData";
import useCountries from "./useCountries";
import { cmsStaticPropertyIds, deepClone, modelingCategoriesHierarchy } from "../utils";
import { housesState, organizationState, userState } from "../atoms";
import { TYPES, defaultMatchBarState, defaultVisbileParameters, fieldsUnderProperties, importanceRanksOptions, lodOptions, sustainabilityPillarsOptions } from "../components/master-search/utils";
import usePropertiesNew from "./usePropertiesNew";


export default function useMatchBarNew(hookArgs = {}) {

    const { visibileParameters = defaultVisbileParameters, otherOrganizations = [], type } = hookArgs

    // API
    const [getAllHouses] = useLazyQuery(GET_ALL_HOUSES);

    // global states
    const [houses, setHouses] = useAtom(housesState);
    const { id: orgId, displayName: orgName } = useRecoilValue(organizationState);
    const userData = useRecoilValue(userState);

    const defaultMatchBarData = useMemo(() => ({ ...defaultMatchBarState, organizations: [orgId] }), [orgId])

    // local state
    const [matchBarData, setMatchBarData] = useState(defaultMatchBarData)

    // hooks
    const { brands, distributors, categories: allCategories } = useGetFacetsData();

    const {
        getMatchbarData,
        getOptionsByPropertyId,
    } = usePropertiesNew(orgId);

    const countries = useCountries()

    // const parenCategories = useMemo(() => allCategories.filter((category) => !category.parentCategoryId), [allCategories])

    const categoriesHeirarchy = useMemo(() => modelingCategoriesHierarchy(allCategories), [allCategories])

    // sub categories in categorised heirarchy format 
    // const subCategories = useMemo(() => filteredSubCatAccToParentCat(allCategories, matchBarData?.[TYPES.CATEGORIES] || []), [allCategories, matchBarData])

    // fetching houses/projects list if not found in global storage
    useEffect(() => {
        if (!houses?.length && orgId && visibileParameters?.[TYPES.PIRS]) {

            async function getOrgProjects() {
                const { data: housesData } = await getAllHouses();
                const allHouses = housesData?.getAllHouses || [];
                setHouses([...allHouses]);
            }
            getOrgProjects();
        }
    }, [getAllHouses, houses, setHouses, orgId, visibileParameters]);

    // 
    const organizations = useMemo(() => {

        if (otherOrganizations && Array.isArray(otherOrganizations)) {
            return ([
                ...otherOrganizations,
                {
                    id: orgId,
                    displayName: orgName,
                }
            ])
        } else {
            return (Array.isArray(userData?.organizations) ? userData.organizations : [])
        }

    }, [userData, otherOrganizations, orgId, orgName]);

    // a common function to be used for discipline and tags option population
    const getDisciplineOrTagsCommon = useCallback((propertyId) => {
        const selectedOrganizationIds = [...matchBarData[TYPES.ORGANIZATIONS]]

        function getBucketData(organizationId) {
            const bucketData = getOptionsByPropertyId({ propertyId, organizationId}).map((option) => {
                // combining ids to avoid duplication
                const id = `${option.id}:${organizationId}`
                return { ...option, id, organizationId };
            })

            return bucketData
        }

        //non Filtered Options type multiselection
        let options = getBucketData(orgId)
        
        // filtered option type categorised selection
        if (visibileParameters[TYPES.ORGANIZATIONS]) {
            options = selectedOrganizationIds.map((organizationId) => ({
                id: organizationId,
                displayName: organizations.find((organization) => organization.id === organizationId)?.displayName,
                buckets: getBucketData(organizationId)
            }))
        }

        return options

    }, [matchBarData, orgId, organizations, visibileParameters, getOptionsByPropertyId])

    const disciplines = useMemo(() => getDisciplineOrTagsCommon(cmsStaticPropertyIds.disciplines), [getDisciplineOrTagsCommon])
    const tags = useMemo(() => getDisciplineOrTagsCommon(cmsStaticPropertyIds.searchTags), [getDisciplineOrTagsCommon])


    // handling on change of all params 
    const onChange = useCallback((changedData, type) => {
        let updatedMatchBarData = deepClone(matchBarData)
        updatedMatchBarData[type] = changedData
        setMatchBarData(updatedMatchBarData)
        return updatedMatchBarData
    }, [matchBarData])


    // to get options for field types propertyKeys & propertyWithValues
    const getOptionsOfPropertyOptionsAndValueOptions = useCallback(() => {

        const removeParameterDisplaySections = () => {
            if (type === "public-catalog") {
                // rfa section id
                return ["11ca7fb7-4037-4d41-aef4-82a4bd26a1d7"];
            }
            return [];
        };

        const options = getMatchbarData(matchBarData[TYPES.CATEGORIES], matchBarData[TYPES.PROPERTY_KEYS], removeParameterDisplaySections())

        return options
    }, [getMatchbarData, matchBarData, type])

    // going to output
    const facets = useCallback((matchBarData) => {
        const facetsData = [];

        if (visibileParameters?.[TYPES.BRANDS_AND_DISTRIBUTORS] && matchBarData?.[TYPES.BRANDS_AND_DISTRIBUTORS].length) {

            function pushFacetsData(brandsOrDistributorsOptions, type) {
                // common function to set facet data for brands and distributors
                const brandsOrDistributorsFacets = brandsOrDistributorsOptions.filter((brandOrDistributor) => matchBarData?.[TYPES.BRANDS_AND_DISTRIBUTORS].includes(brandOrDistributor.id)).map((brandOrDistributor) => brandOrDistributor.id);
                if (brandsOrDistributorsFacets.length) {
                    facetsData.push({
                        id: type,
                        values: brandsOrDistributorsFacets,
                    });
                }
            }

            pushFacetsData(brands, 'brands')
            pushFacetsData(distributors, 'distributors')

        }

        if (matchBarData?.[TYPES.CATEGORIES].length) {
            facetsData.push({
                id: "categories",
                values: matchBarData[TYPES.CATEGORIES],
            });
        }

        return facetsData;
    }, [brands, distributors, visibileParameters]);

    // NOTE: these keys are according to what frontend supports 
    const optionsOutput = useMemo(() => {

        const brandsAndDistributors = [
            {
                id: "brand",
                displayName: "Brand",
                buckets: brands,
            },
            {
                id: "distributor",
                displayName: "Distributors",
                buckets: distributors,
            },
        ]

        return ({
            disciplines,
            tags,
            organizations,
            // categories here consists both parent and sub categories
            categories: categoriesHeirarchy,
            countries,
            houses,
            brands,
            distributors,
            brandsAndDistributors,
            propertyKeys: getOptionsOfPropertyOptionsAndValueOptions().optionsForProperties,
            propertyWithValues: getOptionsOfPropertyOptionsAndValueOptions().optionsForPropertyValues,
            lod: lodOptions,
            importanceRanks: importanceRanksOptions,
            sustainabilityPillars: sustainabilityPillarsOptions
        })

    }, [brands, countries, disciplines, distributors, getOptionsOfPropertyOptionsAndValueOptions, houses, organizations, categoriesHeirarchy, tags])


    const getPropertiesFieldData = useCallback((matchBarData) => {
        const propertiesData = [];

        fieldsUnderProperties.forEach(({ type, id }) => {
          
            if (matchBarData?.[type]?.length) {
                const splitIds = matchBarData[type].map(combinedIds => combinedIds.split(":")[0])
                propertiesData.push({
                    id,
                    values: [...new Set(splitIds)],
                });
            }
        })

        if (matchBarData?.[TYPES.PROPERTY_KEYS]?.length) {

            const propertyKeysValueData = matchBarData?.[TYPES.PROPERTY_WITH_VALUES]?.length ? [...matchBarData[TYPES.PROPERTY_WITH_VALUES]] : []
            matchBarData[TYPES.PROPERTY_KEYS].forEach((propertyKeyId) => {

                const propertyKeyData = { id: propertyKeyId }
                if (propertyKeysValueData.length) {
                    const allValuesIds = []

                    // Note: To use below commented code if not combined two ids to differentiate in UI
                    // geting all value options from property values options data for this particular property key and then filtering out selected vaules who comes under this property key
                    // const allValuesIds = optionsOutput[TYPES.PROPERTY_WITH_VALUES].find(propertyKeyOptionData => propertyKeyOptionData.id === propertyKeyId)?.buckets.filter(propertyValueData => propertyKeysValueData.includes(propertyValueData.id))?.map(data => data.id) || []

                    propertyKeysValueData.forEach(propertyKeyAndPropertyValueIds => {
                        // have kept combination of ids for property value to remove UI selection conflict for same property value in diff property key section
                        const splitOfIds = propertyKeyAndPropertyValueIds.split(":")
                        const propertyKeyIdFromSplit = splitOfIds[1]
                        const propertyValueIdFromSplit = splitOfIds[0]

                        if (propertyKeyId === propertyKeyIdFromSplit) {
                            allValuesIds.push(propertyValueIdFromSplit)
                        }
                    })

                    if (allValuesIds.length) {
                        propertyKeyData.values = allValuesIds
                    }
                }
                propertiesData.push(propertyKeyData)

            });
        }

        return propertiesData
    }, [])

    const onResetAll = useCallback(() => {
        setMatchBarData(defaultMatchBarData)
    }, [defaultMatchBarData])

    const getMatchBarOutput = useCallback((outsideHookMatchbarData) => {

        const finalMatchBarData = outsideHookMatchbarData ? { ...defaultMatchBarData, ...outsideHookMatchbarData } : matchBarData

        // NOTE:  these keys are according to what backend supports...
        const backendFormatOutput = {
            countries: finalMatchBarData?.[TYPES.COUNTRIES] || [],
            facets: facets(finalMatchBarData),
            text: (finalMatchBarData[TYPES.TEXT].length >= 3 ? finalMatchBarData[TYPES.TEXT] : ''),
            displayName: (finalMatchBarData[TYPES.NAME].length >= 3 ? finalMatchBarData[TYPES.NAME] : ''),
            description: (finalMatchBarData[TYPES.DESCRIPTION].length >= 3 ? finalMatchBarData[TYPES.DESCRIPTION] : ''),
            bimmatchStandard: finalMatchBarData?.[TYPES.SUSTAINABILITY_PILLARS],
            bimFile: !!finalMatchBarData?.[TYPES.BIM_FILE],
            gwp: !!finalMatchBarData?.[TYPES.GWP],
            properties: getPropertiesFieldData(finalMatchBarData),
            organizationIds: finalMatchBarData?.[TYPES.ORGANIZATIONS],
            houses: finalMatchBarData?.[TYPES.PIRS] || [],
            responsibilityRoles: finalMatchBarData?.[TYPES.ROLES] || [],
            sectionKeywords: finalMatchBarData?.[TYPES.KEY_WORDS] || [],
            importance: finalMatchBarData?.[TYPES.IMPORTANCE_RANKS] || [],
            categories: finalMatchBarData?.[TYPES.CATEGORIES] || [],
            brandsAndDistributors: finalMatchBarData?.[TYPES.BRANDS_AND_DISTRIBUTORS] || [],
            propertyKeys: finalMatchBarData?.[TYPES.PROPERTY_KEYS] || [],
            propertyWithValues: finalMatchBarData?.[TYPES.PROPERTY_WITH_VALUES] || [],
            materials: finalMatchBarData?.[TYPES.MATERIALS] || [],
            lod: finalMatchBarData?.[TYPES.LOD] || ""
        };

        return { output: finalMatchBarData, backendFormatOutput }
    }, [facets, matchBarData, getPropertiesFieldData, defaultMatchBarData])

    return ({ getMatchBarOutput, options: optionsOutput, onChange, onResetAll, setMatchBarData })
}

// const getUpdatedSubCategory = (changedParentCatIds, oldSelectedSubCat, allCategories) => {
//     let updatedSubCat = []

//     if (!oldSelectedSubCat?.length) {
//         return updatedSubCat
//     }

//     const updatedSubCategories = filteredSubCatAccToParentCat(allCategories, changedParentCatIds)
//     const subCatIds = updatedSubCategories.length ? updatedSubCategories.map(parentCategory => parentCategory.buckets).flat().map(subCategory => subCategory.id) : []
//     updatedSubCat = oldSelectedSubCat.filter(subCatId => subCatIds.includes(subCatId))
//     return updatedSubCat
// }

